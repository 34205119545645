const pt = {
  translation: {
    header: {
      search: "Procurar...",
      language: "Linguagem",
    },
    card: { button: { addToCart: "Adicionar ao Carrinho" ,productDetail:'detalhes do produto', } },
    productDetails:{addToWishList:'Adicionar a lista de desejos',viewShop:'Ver loja',chatWithUs:'Converse conosco'},
    myOrders:{filters:'filtors',orderStatus:"Status do pedido",onTheWay:"A caminho",delivered:"Entregue",cancelled:"cancelada",returned:"retornou",orderTime:"Hora do pedido",last30Days:"Últimos 30 dias",older:"Mais velho",soldBy:'Vendido por',viewOrder:'Ver pedido',cancelOrder:"cancelar pedido",address:"Endereço",paymentDetail:"Detalhes de pagamento "},
    // card: { button: { addToCart: "Add to Cart" ,productDetail:'View Details'} },
    shop:{categoryHeading:'categorias',showingHeading:'mostrando',size:'Tamanho',price:'Preço',brand:'Marca',discount:'Desconto',andMore:'e mais',color:'cor'},
    home: {
      button: { viewAll: "Ver Tudo" },
      categories: { title: "Categorias" },
      brand: { title: "Comprar Por Marcas" },
      fCollection: { title: "Coleção em Destaque" },
      trProducts: { title: "Produtos em Alta" },
      flashSale: { title: "Venda rápida" },
      topProducts: { title: "Principais Produtos" },
      nearbySellers: { title: "Vendedores próximos" },
  
      
      providingFeatures: {
        i: {
          main: "Frete Grátis e Devoluções",
          secondary: "Frete Grátis em Todos os Pedidos dos EUA.",
        },
        ii: {
          main: "Garantia de Dinheiro",
          secondary: "Garantia de Devolução do Dinheiro em 30 Dias",
        },
        iii: {
          main: "Suporte Online",
          secondary: "Apoiamos Online 24/7 no Dia",
        },
        iv: {
          main: "Pagamentos Seguros",
          secondary: "Todos os pagamentos são seguros e confiáveis",
        },
      },
    },
  },
};

export default pt;
