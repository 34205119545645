import axios from "axios";
import JwtService from "../Services/jwtService";
import AppConfig from "../AppConfig";
const lang = localStorage.getItem('i18nextLng')
const http = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: 75000,
  headers: {
    Accept: "application/json",
    // "lang":lang,
    Authorization : "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZDg2OTVlYjU2MTcyNjVkYjhjYmZiYTE0ZmE0NDI5ZjhjNWY3YjJkMGUzZTViZGE3MGZkYWVjMDVkOGEwZDFlMTdiOTlkNTVlNWQ0OGFlZjQiLCJpYXQiOjE2NTk2ODMzMTYuNzcyNTQxOTk5ODE2ODk0NTMxMjUsIm5iZiI6MTY1OTY4MzMxNi43NzI1NDUwOTkyNTg0MjI4NTE1NjI1LCJleHAiOjE2OTEyMTkzMTYuNzY4NjUxOTYyMjgwMjczNDM3NSwic3ViIjoiMiIsInNjb3BlcyI6W119.Rh86fJ7pB5TDBSxhQX8pM0odb2lFXKJ3LBIMTnPkLGN_9VZa8Y-sL4RCltLmvVJ9h3gScnkeP28MqA-XMoO2TmsRMJXOShWX5lbjXn-cqUnBS4aIiPs2i7zK22ykh7LDlzqhEQwPYKCn_wJNP8l1VUwIMpt_RcidFM5N3-msmzdZP_gzyWR7tM2x-yIcG-7KTBr8pPOSjh13_yedR2copyMVML6EJnFhP0DB84RpUojVcnBfzGh1qYn-6xXgC5s98qmknnBTSUC-MVv4w6eodkaJie113Pe2MhbIpGPMLCmuZZGK57wkKIJTtjvhF-cMRU3Sg7zFjonWbFWzUgd_YZhfTGUq1YH-XBA3hMJ1TFyeOgUavyITSQ5ZPwn6WSHnFJz9eMsBMu2Rsl7guKSo_j1k8q45ztwFdKw_fELBjQN41OG4vdspQg0cBMG15gblPkgyTIC98Xx4BmlkXuVG5pbzzk-0OHBJaODNAfykkYjKN013nZObjJWcGxkzKwvWQ_HW2IYoyfC8ncoD6ix8sqA2rvCDB6QVh5M4hXJQTJlMMoRPGb4hRP0XMTqc7RWyPFVXGj2pd0Nuuz69rF95CUvspVllhbdB9ZaMW5HmcKI9oN3rPyMO3cPLzRQEp1tuRAf_RDst1WCpwKt1KxzQpp1d7IWny6A_4fNaOnXzojc",
    apiKey:"jjfVFMHDg9NDu5jDEPVjSxmU4hVxBXnF",
    'Access-Control-Allow-Methods': 'OPTIONS GET, POST',
    "Content-Type": "multipart/form-data",
    'Access-Control-Allow-Origin': "*",
    "Access-Control-Allow-Credentials": "false",
  },
});

// http.interceptors.request.use(async (config) => {
//   if (JwtService.getAccessToken()) {
//     const token = JwtService.getAccessToken();

//     config.headers.Authorization = token ? "Bearer " + token : "Bearer ";
//   }

//   return config;
// });

http.interceptors.response.use(
  async function (response) {
    return { status: true, code: response.status, data: response.data };
  },
  function ({ response }) {
    // return Promise.reject({status: false, code: response.status, ...response.data});
    if (typeof response !== "undefined") {
      return { status: false, code: response.status, ...response.data };
    } else {
      return { status: false, code: 504, message: "Gateway Timeout." };
    }
  }
);

export default {
  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  get: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .get(url, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  getSync: function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .get(url, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  post: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  put: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .put(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */

  delete: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .delete(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  options: async function (url, data) {
    return new Promise((resolve, reject) => {
      http
        .options(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetch: (options) => http(options),
};
