import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActionTypes } from "../../../Redux/Contants/ActionType";
import { useHistory } from "react-router";
import store from "../../../Redux/Store";
import Addbanner from "../../Common/Addbanner";
import AppConfig from "../../../AppConfig";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import ApiService from "../../../Services/apiService";

export default function AdvertisementSection() {
 
  const [open, setOpen] = React.useState(false);
  const [cmsdata, setCmsdata] = useState();

  const cms_data = useSelector(({ allCategories }) => allCategories.cmsData);
  const location = useLocation();
  const [inquiryForm, setInquiryform] = useState({
    name: "",
    email: "",
    phone_no: "",
    message: ""
  })

  useEffect(() => {
    setTimeout(() => {
     window.addEventListener("scroll", function (event) {
      //  console.log(document.documentElement.scrollTop)
      //  console.log(document.getElementById("advertisementNewsMocs").scrollHeight, window.screen.availHeight)
       const finalH = (document.getElementById("advertisementNewsMocs").scrollHeight-window.screen.availHeight)
      //  console.log(finalH)
       if(finalH > 0) 
       {
        if(finalH <= document.documentElement.scrollTop ){
          console.log("hello")
          document.getElementById("advertisementNewsMocs").classList.add('sticky_positions')
          if(document.getElementById("advertisementNewsMocs").classList.contains('relative_position')){
            document.getElementById("advertisementNewsMocs").classList.remove('relative_position')
        }
       
          alert('hii')
        }
        else if(finalH >= document.documentElement.scrollTop  )
        document.getElementById("advertisementNewsMocs").classList.add('relative_position')
            if(document.getElementById("advertisementNewsMocs").classList.contains('sticky_position')){
              document.getElementById("advertisementNewsMocs").classList.remove('sticky_positions')
          }
        // }
        
       }
       else {
        if(document.getElementById("advertisementNewsMocs").classList.contains('relative_position')){
          document.getElementById("advertisementNewsMocs").classList.remove('relative_position')
          document.getElementById("advertisementNewsMocs").classList.add('sticky_positions')
      }
       
       }
    
 
     });
   }, 1000);
}, [])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    setOpen(false);
  };
  const advertiseInquiry = () => {
    const _inquiry = new FormData()
    _inquiry.append('name', inquiryForm.name)
    _inquiry.append('email', inquiryForm.email)
    _inquiry.append('phone_no', inquiryForm.phone_no)
    _inquiry.append('message', inquiryForm.message)
    ApiService.enquiryForm(_inquiry).then((response) => {
      alert(response.data.message)
      if (response.code == 200) {
        setInquiryform({
          name: "",
          email: "",
          phone_no: "",
          message: ""

        })
      }
    })
    setOpen(false);
  }
  const history = useHistory();

  const inputInquiry = (event) => {
    setInquiryform({
      ...inquiryForm, [event.target.name]: [event.target.value]
    })
  }

  return (
    typeof cmsdata !== undefined && (
      <>
        {location.pathname.includes("search_news") ? null : (

          <div
            // style={{ position: "sticky", top: "0px" }}
            id="advertisementNewsMocs"
            className={` h-fit w-12/12 flex flex-col relative_position items-center shadow-lg `}
          >
            <Dialog open={open} onClose={handleClose} className='h-3/5'>
              <DialogTitle className="text-center my-2">Inquiry Form</DialogTitle>
              <DialogContent>
                <div className="flex px-2">
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name *"
                    value={inquiryForm.name}
                    onChange={inputInquiry}
                    type="text"
                    fullWidth
                    variant="outlined"
                    className="mx-3"
                  />
                  <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    value={inquiryForm.email}
                    label="Email Address *"
                    onChange={inputInquiry}
                    type="email"
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="px-2">
                  <TextField
                    value={inquiryForm.phone_no}
                    margin="dense"
                    id="phone"
                    name="phone_no"
                    onChange={inputInquiry}
                    label="Phone *"
                    type="number"
                    variant="outlined"
                    className=" pl-3 pr-1 w-full"
                  />
                </div>
                <div className="px-2">
                  <TextField
                    value={inquiryForm.message}
                    margin="dense"
                    id="message"
                    name="message"
                    label="Message"
                    onChange={inputInquiry}
                    type="text"
                    multiline
                    minRows={4}
                    variant="outlined"
                    className=" pl-3 pr-1 w-full"
                  />
                </div>

              </DialogContent>
              <DialogActions className="w-full">
                <div className="w-full text-center"> <Button variant="contained" onClick={advertiseInquiry} className='w-24 text-whiteBackground font-semibold py-1 rounded bg-green-600 text-center border-2'>Submit</Button></div>
                {/* <Button onClick={handleClose}>Subscribe</Button> */}
              </DialogActions>
            </Dialog>
            <div>
              <div
                style={{ marginBottom: "64px" }}
                id="emptyDivAtTopInAdvertisement"
              ></div>
            </div>
            <div
              id="mainAdvertisementDiv"
              className=" my-2 flex flex-col items-center"
            >
              <div className="h-fit w-52">
                <Addbanner id1={4} height="200px" width='250px'/>
              </div>
              <h1 className="text-base  dark:text-fontWhite">
                Download app from
              </h1>
              <a href="https://play.google.com/store/apps/details?id=com.localsamachar" target={'_blank'}>
              <img
                className="my-1"
                src="https://www.bhaskar.com/assets/images/playstore-a.58f8fbd8.svg"
              />
              </a>
            </div>
            <div className=" my-2 dark:text-fontWhite flex flex-col items-center">
              <h1 className="text-base dark:text-fontWhite ">Advertisement </h1>

            </div>
            <div className="flex-1 h-full  flex-col flex items-center">
              <div className="h-fit w-52"> <Addbanner id1={5} height="200px" width='250px'/> </div>
              {/* <Addbanner id1={0} height="200px" width='300px'/> */}
            </div>
            <div className="px-7 mx-3" id="footer">
              <div className="grid grid-cols-1 ">
                <p onClick={handleClickOpen} className="my-1 text-fontDark hover:underline cursor-pointer dark:text-fontWhite py-4 font-semibold">
                  Advertise with us
                </p>

                {cms_data?.map((data) => (
                  data.title == 'Home' ? "" :
                    <p
                      className="my-1 px-2 cursor-pointer text-fontDark dark:text-fontWhite"
                      onClick={() =>
                        history.push({
                          pathname: `/cms/${data.slug}`,
                          state: {
                            id: null,
                            type: "cms",
                            brandName: data,
                          },
                        })
                      }
                    >
                      {data.title}
                    </p>
                ))}

                <p className="my-1 dark:text-fontWhite font-bold py-5 pt-8 text-fontDark">
                  {AppConfig.copyright}
                </p>
              </div>
            </div>
            <div
              style={{ display: "none" }}
              className="mt-2"
              id="lastEmptyDiv"
            ></div>
          </div>
        )}
      </>
    )
  );
}

