import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import top from "../../../Assets/images/top.png";
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment'
import { useParams } from "react-router-dom";
import ApiService from "../../../Services/apiService";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import store from "../../../Redux/Store";
import { ActionTypes } from "../../../Redux/Contants/ActionType";
import { ThreeDots } from "react-loader-spinner";
import Addbanner from "../../Common/Addbanner";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function News(props) {
  console.log(props)

  var scroll_div =document.getElementById('scrollDiv')
  const currentTheme = window.localStorage.getItem("theme");
  const rolldata = document.getElementById('news_div')
  const [newsLoader, setNewsLoader] = useState(false);
  const newsData = useSelector(({ allCategories }) => allCategories.newsData);
  const loadMoreNews = useSelector(
    ({ allCategories }) => allCategories.loadMoreNews
  );

 
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const useStyles = makeStyles(() => ({
    text: {
      "& h3": { color: currentTheme === "dark" ? "#fff" : "#050505" },
      "& p": { color: currentTheme === "dark" ? "#fff" : "#050505" },
      "& span": { color: currentTheme === "dark" ? "#fff" : "#050505" },
      "& h2": { color: currentTheme === "dark" ? "#fff" : "#050505" },
      "& h1": { color: currentTheme === "dark" ? "#fff" : "#050505" },
      "& img": { display: "none" },
      "& p": { color: "black" },
    },
  }));

  const banner = useSelector(({ allCategories }) => allCategories.adsbanner);
  const news_headlines = useSelector(({ allCategories }) => allCategories.newsData)
  const banner_length = banner.length;

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videocontrol, setVideocontrol] = useState(false);
  const [overlaykey, setOverlaykey] = useState(0)
  const params = useParams();
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {

    // const data_div = document.getElementById('news_div')

    window.onscroll = function isInViewport() {
          const element = document.getElementById("lastNewsDiv");
          const rect = element.getBoundingClientRect();
          if (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= 
              (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
          ) {
            if (loadMoreNews == true) {
              store.dispatch({
                type: ActionTypes.LOAD_MORE_NEWS,
                payload: false,
              });
              if (page == lastPage) {
              } else {
                setNewsLoader(true)
                setPage(page + 1);
              }
            }
          }
        };

  });

  useEffect(() => {
    setPage(1);
  }, [params]);

  function isInViewport() {
    const element = document.getElementById("lastNewsDiv");
    const rect = element.getBoundingClientRect();
    if (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <=
      (window.innerWidth || document.documentElement.clientWidth)
    ) {
      if (loadMoreNews == true) {
        store.dispatch({
          type: ActionTypes.LOAD_MORE_NEWS,
          payload: false,
        });
        if (page == lastPage) {
        } else {
          setPage(page + 1);
        }
      }
    }
  };


  useEffect(() => {
    headlinesData()
  }, [page]);

  const headlinesData = () => {
    if (props.similarNews) {
      store.dispatch({
        type: ActionTypes.NEWS_DATA,
        payload: [props.similarNews, false],
      });
      setLoading(false);
      setNewsLoader(false);
    } else {
      setNewsLoader(true);

      if (params.id) {
        const form = new FormData();
        if (params.type == "category") {
          if (params.id == "top-news") {
            form.append("title", "top news");
          } else {
            form.append("category_id", params.id);
          }
        } else {
          let categoryId = "";
          let cityId = "";
          let areaId = "";
          const searchedValue = query.get("search");
          const category = query.get("category");
          if (category != null) {
            categoryId = category.split("/")[1];
          }
          const city = query.get("city");
          if (city !== null) {
            cityId = city.split("/")[1];
          }

          const area = query.get("area");
          if (area != null) {
            areaId = area.split("/")[1];
          }
          if (searchedValue !== "") {
            form.append("search", searchedValue);
          }
          if (areaId !== "") {
            form.append("area_id", areaId);
          }
          if (categoryId !== "") {
            form.append("category_id", categoryId);
          }
          if (cityId !== "") {
            form.append("city_id[0]", cityId);
          }
        }
        form.append("page", page);
        ApiService.getNews(form).then((res) => {
          if (Array.isArray(res.data.NewsList)) {
            setNews(res?.data?.NewsList);
            setLoading(false);
          } else {
            store.dispatch({
              type: ActionTypes.NEWS_DATA,
              payload: [res.data.NewsList.data, page > 1 ? true : false],
            });
            setLoading(false);
            setNewsLoader(false);
            setLastPage(res.data.NewsList.last_page);
            store.dispatch({
              type: ActionTypes.LOAD_MORE_NEWS,
              payload: true,
            });
          }
        });
      }
      else {
        const form = new FormData();
        form.append("page", page);
        ApiService.getNews(form).then((res) => {
          store.dispatch({
            type: ActionTypes.NEWS_DATA,
            payload: [res.data.NewsList.data, true],
          });
          setLoading(false);
          setLastPage(res?.data?.NewsList?.last_page);
          setNewsLoader(false);
          store.dispatch({
            type: ActionTypes.LOAD_MORE_NEWS,
            payload: true,
          });
        });
      }
    }
  }

  const theme = window.localStorage.getItem("theme");
  const history = useHistory();

  const classes = useStyles();


  return (
    typeof newsData !== undefined && (
      <div id="news_div">
        {loading ? (
          <>
            <div
              id="mainnews"
              style={{ backgroundColor: "#FFF", flex: "3" }}
              className={`dark:bg-darkBackground ${window.screen.availWidth < 1024 && "mt-2"
                }  cursor-pointer h-500  flex  justify-center items-center`}
            >
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#f89c1d"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          </>
        ) : (
          <div
            id="mainnews"
            style={{ backgroundColor: "#FFF", flex: "2" }}
            className={`dark:bg-darkBackground mt-4 md:mx-9 mx-1 w-12/12 cursor-pointer overflow-y-auto  `}
          >
            <Addbanner id1={1} height="250px" width="100%" />
            {newsData?.length == 0 ? (
              <div className="flex justify-center w-full ">
                <h1 className="text-4xl my-8 text-fontDark dark:text-fontWhite uppercase">
                  No News!!
                </h1>
              </div>
            ) : (
              <>
                <div id='scrollDiv' 
                >
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={() => isInViewport}
                    hasMore={true || false}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                    useWindow={false}
                  >
                    {newsData?.map((ele, key) => (
                      <>
                        <div
                          onClick={() => {
                            const scroll_div =document.getElementById('scrollDiv')
                            // scroll_div.scrollTo(0,0)
                            scroll_div.scrollTop = 0;
                            history.push(
                              `/detail/${ele?.category.name}/${ele?.category.id}/${ele?.slug}/${ele.id}`
                            );
                           
                          }}
                          className=" p-2 newsCard  border-1 border-gray-300  hover:shadow-xl mx-1  duration-300  md:px-3 dark:bg-lightDarkBackground bg-whiteBackground  my-2"
                        >
                          <div className="grid grid-cols-5 ml-1">
                            <div
                              className="col-span-2 md:p-0 lg:my-0 md:my-0 my-4 px-2 mx-1 "
                            >
                              {ele.type_of_news == "Image" ?
                                <img
                                  className="md:w-full w-fit border border-gray-400 col-span-3 mx-3 md:mr-0 md:h-32 h-24 object-contain"
                                  src={ele.ImageSrc}
                                /> : <div>
                                  <video
                                    width="100%"
                                    height="600"
                                    onMouseEnter={() => {
                                      setVideocontrol(true);
                                      setOverlaykey(key)
                                    }}
                                    onMouseLeave={() => {
                                      setVideocontrol(false);
                                    }}
                                    className=" w-full"
                                    controls={overlaykey === key ? videocontrol ? true : false : ""}
                                  >
                                    <source src={ele.VideoSrc} type="video/mp4" />
                                  </video>

                                </div>}

                            </div>
                            <div className="flex  flex-col col-span-3 mx-3">
                              <div
                                style={{
                                  overflowWrap: "break-word",
                                  wordBreak: "break-word",
                                }}
                                className={` md:ml-2 ml-0 md:my-0 my-2 md:text-xl text-lg leading-6 capitalize font-light hover:text-primary`}
                              >
                                <div className="md:text-gray-500 text-gray-800 lg:hidden md:hidden block dark:text-fontWhite uppercase  text-base font-semibold mb-2 md:ml-2 ">
                                {ele?.category?.name}
                              </div>
                                {ele?.title}
                              </div>

                              <div
                                style={{ overflowWrap: "break-word" }}
                                className={clsx(
                                  classes.text,
                                  "md:ml-2 lg: md:block hidden dark:text-fontWhite text-fontDark md:mt-2"
                                )}
                              >
                                {ele?.short_description}
                              </div>


                            </div>
                          </div>
                          <div className="flex md:flex-row  flex-col justify-between px-2 items-center">
                            <div className="flex md:w-1/2 w-full  md:justify-start justify-between ">
                              <div className="  text-gray-500 dark:text-fontWhite my-2 md:ml-2 ">
                                {moment(ele?.created_at).format('DD MMM YYYY')}
                              </div>
                              {ele?.city == null ? "" : <div className="text-gray-500 dark:text-fontWhite my-2 ml-2  ">
                                {ele?.city.name}
                              </div>}
                              <div className="my-auto lg:hidden md:hidden block text-right">
                                By : {ele?.author.first_name}
                              </div>

                            </div>
                            <div className="flex justify-between md:w-1/2 w-full">
                            <div className="my-auto lg:block md:block hidden text-right">
                                By : {ele?.author.first_name}
                              </div>
                              <div className="md:text-gray-500 text-end w-12/12 text-gray-800 lg:block md:block hidden dark:text-fontWhite uppercase  text-base my-2 md:ml-2 ">
                                {ele?.category?.name}
                              </div>
                            </div>
                          </div>
                          <div className="md:w-1/3 w-2/3 flex flex-row md:px-4 my-2 items-center">
                            <div className="text-darkBackground text-base  font-light">Share On:</div>

                            <div className="flex md:px-2 w-1/2 justify-between">
                              <a href="https://www.facebook.com/login/?privacy_mutation_token=eyJ0eXBlIjowLCJjcmVhdGlvbl90aW1lIjoxNjYyMDA1NzU4LCJjYWxsc2l0ZV9pZCI6MjY5NTQ4NDUzMDcyMDk1MX0%3D" target='_blank'> <FacebookIcon /> </a>
                              <a href="https://www.instagram.com/accounts/login/?source=auth_switcher" target='_blank'>  <InstagramIcon /> </a>
                              <a href="https://twitter.com/i/flow/login" target='_blank'> <TwitterIcon />  </a></div>

                          </div>
                        </div>


                        {key == 0 ? "" : (key % 3 == 0) ? < Addbanner id={Math.floor(Math.random() * banner_length) - 1} width='300px' height='200px' />
                          : ''}
                      </>
                    ))}
                  </InfiniteScroll>
                </div>
                <div
                  id="lastNewsDiv"
                  onClick={() => {
                    window.scroll(0, 0);
                    rolldata.scroll(0, 0)

                    history.push(
                      `/detail/${newsData[newsData?.length - 1].slug}/${newsData[newsData?.length - 1].id
                      }`
                    );
                  }}
                  className="  border-1 border-gray-300 p-2  hover:shadow-xl mx-1  duration-300  md:px-3 dark:bg-lightDarkBackground bg-whiteBackground  my-2 "
                >
                   <div className="grid grid-cols-5 ml-1">
                            <div
                              className="col-span-2 md:p-0 lg:my-0 md:my-0 my-4 px-2 mx-1 "
                            >
                              {newsData[newsData?.length - 1].type_of_news == "Image" ?
                                <img
                                  className="md:w-full w-fit border border-gray-400 col-span-3 mx-3 md:mr-0 md:h-32 h-24 object-contain"
                                  src={newsData[newsData?.length - 1].ImageSrc}
                                /> : <div>
                                  <video
                                    width="100%"
                                    height="600"
                                    onMouseEnter={() => {
                                      setVideocontrol(true);
                                      // setOverlaykey(key)
                                    }}
                                    onMouseLeave={() => {
                                      setVideocontrol(false);
                                    }}
                                    className=" w-full"
                                    controls
                                  >
                                    <source src={newsData[newsData?.length - 1].VideoSrc} type="video/mp4" />
                                  </video>

                                </div>}

                            </div>
                            <div className="flex  flex-col col-span-3 mx-3">
                              <div
                                style={{
                                  overflowWrap: "break-word",
                                  wordBreak: "break-word",
                                  // color: generateRandomColor(ele),
                                }}
                                className={` md:ml-2 ml-0 md:my-0 my-2 md:text-xl text-lg leading-6 capitalize font-light hover:text-primary`}
                              >
                                <div className="md:text-gray-500 text-gray-800 lg:hidden md:hidden block dark:text-fontWhite uppercase  text-base font-semibold mb-2 md:ml-2 ">
                                {newsData[newsData?.length - 1]?.category?.name}
                              </div>
                                {newsData[newsData?.length - 1]?.title}
                              </div>

                              <div
                                style={{ overflowWrap: "break-word" }}
                                className={clsx(
                                  classes.text,
                                  "md:ml-2 lg: md:block hidden dark:text-fontWhite text-fontDark md:mt-2"
                                )}
                              >
                                {newsData[newsData?.length - 1]?.short_description}
                              </div>


                            </div>
                          </div>
                          <div className="flex md:flex-row  flex-col justify-between px-2 items-center">
                            <div className="flex md:w-1/2 w-full  md:justify-start justify-between ">
                              <div className="  text-gray-500 dark:text-fontWhite my-2 md:ml-2 ">
                                {moment(newsData[newsData?.length - 1]?.created_at).format('DD MMM YYYY')}
                              </div>
                              {newsData[newsData?.length - 1]?.city == null ? "" : <div className="text-gray-500 dark:text-fontWhite my-2 ml-2  ">
                                {newsData[newsData?.length - 1]?.city.name}
                              </div>}
                              <div className="my-auto lg:hidden md:hidden block text-right">
                                By : {newsData[newsData?.length - 1]?.author.first_name}
                              </div>

                            </div>
                            <div className="flex justify-between md:w-1/2 w-full">
                            <div className="my-auto lg:block md:block hidden text-right">
                                By : {newsData[newsData?.length - 1]?.author.first_name}
                              </div>
                              <div className="md:text-gray-500 text-end w-12/12 text-gray-800 lg:block md:block hidden dark:text-fontWhite uppercase  text-base my-2 md:ml-2 ">
                                {newsData[newsData?.length - 1]?.category?.name}
                              </div>
                            </div>
                          </div>
                          <div className="md:w-1/3 w-2/3 flex flex-row md:px-4 my-2 items-center">
                            <div className="text-darkBackground text-base  font-light">Share On:</div>

                            <div className="flex md:px-2 w-1/2 justify-between">
                              <a href="https://www.facebook.com/login/?privacy_mutation_token=eyJ0eXBlIjowLCJjcmVhdGlvbl90aW1lIjoxNjYyMDA1NzU4LCJjYWxsc2l0ZV9pZCI6MjY5NTQ4NDUzMDcyMDk1MX0%3D" target='_blank'> <FacebookIcon /> </a>
                              <a href="https://www.instagram.com/accounts/login/?source=auth_switcher" target='_blank'>  <InstagramIcon /> </a>
                              <a href="https://twitter.com/i/flow/login" target='_blank'> <TwitterIcon />  </a></div>

                          </div>
                </div>
                {newsLoader && (
                  <div className="w-full flex justify-center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#f89c1d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </div>
                )}
              </>
            )}
            {window.screen.availWidth < 800 && (
              <div className="h-10 mx-auto flex justify-center   mb-20">
                <img
                  onClick={() => {

                    rolldata.scrollTo(0, 0);
                  }}
                  style={{ filter: theme == "dark" ? "invert(100%)" : "" }}
                  src={top}
                  className="w-10 h-10   "
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
}
