import { ActionTypes } from "../Contants/ActionType";

const initialState = {
  categories: [],
  session_id: null,
  user: "",
  cartCount: 0,
  wishlistCount: 0,
  cartItem: [],
  notificationCount: 0,
  cmsData: [],
  cartItems: [],
  InfiniteScollProducts: [],
  newsData: [],
  adsbanner: [],
  loadMoreNews: true,
  searchData : true,
};
export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CATEGORIES: {
      let newState = { ...state };
      newState.categories = action.payload;
      return newState;
    }

    case ActionTypes.ADD_SESSION_ID: {
      let newState = { ...state };
      newState.session_id = action.payload;
      return newState;
    }
    case ActionTypes.ADS_BANNER: {
      let newState = { ...state };
      newState.adsbanner = action.payload;
      return newState;
    }
    case ActionTypes.ADD_USER_DATA: {
      let newState = { ...state };
      newState.user = action.payload;
      return newState;
    }
    case ActionTypes.ADD_USER_CART_COUNT: {
      // let newState = { ...state }
      // newState.cartCount = action.payload;
      // return newState;

      let newState = { ...state };
      if (action.payload == 1 || action.payload == -1) {
        const cartCount = newState.cartCount;
        newState.cartCount = cartCount + action.payload;
        return newState;
      } else {
        let newState = { ...state };
        newState.cartCount = action.payload;
        return newState;
      }
    }
    case ActionTypes.ADD_USER_WISHLIST_COUNT: {
      let newState = { ...state };
      if (action.payload == 1 || action.payload == -1) {
        const currentCount = newState.wishlistCount;
        newState.wishlistCount = currentCount + action.payload;
        return newState;
      } else {
        let newState = { ...state };
        newState.wishlistCount = action.payload;
        return newState;
      }
    }
    case ActionTypes.ADD_USER_CART_ITEM: {
      let newState = { ...state };
      newState.cartItem = [action.payload];
      return newState;
    }
    case ActionTypes.ADD_USER_Notification_COUNT: {
      let newState = { ...state };
      newState.notificationCount = action.payload;
      return newState;
    }
    case ActionTypes.ADD_CMS: {
      let newState = { ...state };
      newState.cmsData = action.payload;
      return newState;
    }
    case ActionTypes.USER_CART_ITEMS: {
      let newState = { ...state };
      newState.cartItems = action.payload;
      return newState;
    }
    case ActionTypes.LOAD_MORE_NEWS: {
      let newState = { ...state };
      newState.loadMoreNews = action.payload;
      return newState;
    }
    case ActionTypes.SEARCH_DATA: {
      let newState = {...state};
      newState.searchData = action.payload
      return newState;
    }
    case ActionTypes.NEWS_DATA: {
      if (action.payload[1] == true) {
        // console.log("this is payload", action.payload);
        let newState = { ...state };
        const data = newState.newsData;
        const finalData = data.concat(action.payload[0]);
        newState.newsData = finalData;
        return newState;
      } else {
        let newState = { ...state };
        newState.newsData = action.payload[0];
        return newState;
      }
    }
    case ActionTypes.INFINITE_SCROLL: {
      // let newState = { ...state }
      // newState.InfiniteScollProducts = action.payload;
      // return newState;
      console.log("this is action payl;oad", action.payload);
      let newState = { ...state };
      const data = newState.InfiniteScollProducts;
      const finalData = data.concat(action.payload);
      newState.InfiniteScollProducts = finalData;
      return newState;
    }

    default:
      return { ...state };
  }
};
