import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ApiService from '../../../Services/apiService'
import { CircularProgress } from '@material-ui/core'
import AppConfig from '../../../AppConfig'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

export default function Search() {
  // const [categories, setCategories] = useState([])
  const [areaData, setAreaData] = useState([])
  const [cities, setCities] = useState([])
  const [categoryKey, setCategoryKey] = useState('')
  const [cityKey, setCityKey] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [cityName, setCityName] = useState('')
  const [search, setSearch] = useState('')
  const [areaName, setAreaName] = useState('')
  const [areaKey, setAreaKey] = useState('')
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(true)

  const categories_data = useSelector(({ allCategories }) => allCategories.categories);
  console.log(categories_data)


  const [categories, setCategories] = useState([categories_data]);
  useEffect(() => {
    // ApiService.getNewsCategories().then((res) => {
    //   console.log("this is res data in search ", res.data.CategoriesDetails)
    //   setCategories(res.data.CategoriesDetails)
    //   setLoading1(false)
    // })
    ApiService.getAllCities().then((res) => {
      console.log("this is res data in search for all city ", res.data)
      setCities(res.data)
      setLoading2(false)

    })
  }, [])
  const history = useHistory()
  const handleChange = (e) => {
    setSearch(e.target.value)
  }
  const getareaData = (id) => {
    ApiService.getAreaData(id).then((res) => {
      console.log("this is response from get area ap", res)
      setAreaData(res.data)
    })
  }
  const startsearching = () => {
    if (search == '' && categoryKey == null && cityKey == null) return
    else {
      // if(search!=='' && categoryKey!==null && cityKey!==null){
      history.push(`/search/news/search?search=${search}&category=${categoryName}/${categoryKey}&city=${cityName}/${cityKey}&area=${areaName}/${areaKey}`)
      // }=

    }
  }
  return (
    <>

      <Helmet>
        <title>Search-{AppConfig.siteName}</title>
        <meta name="description" content="Search your desired news world wide , or in particular category and city and area"></meta>

        <meta name="keywords" content="Trending news,search news,world wide news,top news,local news,sports news ,breaking news,articles,daily news,"></meta>
      </Helmet>
      {

        loading1 == false && loading2 == false ?
          <div className=' pb-16 ' >
            <div className='w-screen top-20 fixed bg-whiteBackground z-2 dark:bg-darkBackground justify-center mx-auto flex'>
              <div className='w-3/4 ' >
                <form onSubmit={startsearching} >
                  <input
                    style={{ border: "none", borderBottom: "1px solid gray" }}
                    value={search}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    className="text-fontDark  dark:text-fontWhite  text-sm   px-4 py-6 " placeholder='Search by keyword ' />
                </form>

              </div>
              <button
                disabled={search == '' && categoryKey == '' && cityKey == ''}
                onClick={startsearching}
                className='my-1 bg-whiteBackground dark:bg-lightDarkBackground dark:text-fontWhite ml-2 shadow-md  outline-none  text-fontDark  px-6 text-base p-1 rounded-lg '>
                Search
              </button>

            </div>


            <div className='md:w-3/4  w-full  my-4 mt-16  mx-auto' >
              <div className='w-full flex justify-center items-center my-2' >
                <h1 className='font-bold text-base dark:text-fontWhite' >Search By Category</h1>
              </div>
              <div className='dark:bg-lightDarkBackground        setAreaData([])d bg-whiteBackground shadow-md' >      {cities.length > 0 &&
                <div className=' flex flex-wrap p-4 mx-0' >
                  {
                    categories_data.length > 0 && categories_data.map((ele, key) => (
                      <div

                        onClick={() => {
                          if (categoryKey == ele.id) {
                            setCategoryKey('')
                            setCategoryName('')

                          }
                          else {
                            setCategoryKey(ele.id)
                            setCategoryName(ele.slug)
                          }
                        }}
                        className={`rounded-full  ${categoryKey == ele.id ? "bg-yellow-300 text-fontDark" : ''} m-2 p-2 px-4 cursor-pointer  border-1 border-yellow-300 flex justify-center items-center`} >
                        <h1 className={`font-bold  ${categoryKey == ele.id ? "dark:text-fontDark" : "dark:text-fontWhite"}  text-sm`} >
                          {
                            ele.name
                          }
                        </h1>
                      </div>

                    ))
                  }
                </div>
              }
              </div>
            </div>
            <div className=' md:w-3/4 w-full   my-2  mx-auto' >
              <div className='w-full flex justify-center items-center my-2' >
                <h1 className='font-bold text-base  dark:text-fontWhite' >Search By city</h1>
              </div>
              <div className='dark:bg-lightDarkBackground bg-whiteBackground shadow-md' >      {cities.length > 0 &&
                <div className='flex flex-wrap p-4 mx-0' >
                  {
                    cities.length > 0 && cities.map((ele, key) => (
                      <div

                        onClick={() => {
                          if (cityKey == ele.id) {
                            setCityKey('')
                            setCityName('')
                            setAreaData([])
                          }
                          else {
                            setCityKey(ele.id)
                            setCityName(ele.slug)
                            getareaData(ele.id)
                          }
                          // history.push(`/category/${ele.slug}/${ele.id}`)
                        }}
                        className={`rounded-full ${cityKey == ele.id ? "bg-yellow-300" : ''} m-2 p-2 px-4 cursor-pointer  border-1 border-yellow-300 flex justify-center items-center`} >
                        <h1 className={`font-bold  ${cityKey == ele.id ? "dark:text-fontDark" : "dark:text-fontWhite"}  text-sm`} >
                          {
                            ele.name
                          }
                        </h1>
                      </div>

                    ))
                  }
                </div>

              }
              </div>

            </div>
            {/* area data  */}

            <div className='md:w-3/4 w-full   my-2  mx-auto' >
              <div className='w-full flex justify-center items-center my-2' >
                {areaData.length > 0 &&
                  <h1 className='font-bold text-base dark:text-fontWhite' >Search By area</h1>}
              </div>
              <div className='dark:bg-lightDarkBackground bg-whiteBackground shadow-md' >      {areaData.length > 0 &&
                <div className='flex flex-wrap p-4 mx-0' >
                  {
                    areaData.length > 0 && areaData.map((ele, key) => (
                      <div

                        onClick={() => {
                          if (setAreaKey == ele.id) {
                            setAreaKey('')
                            setAreaName('')

                          }
                          else {
                            setAreaKey(ele.id)
                            setAreaName(ele.slug)

                          }
                          // history.push(`/category/${ele.slug}/${ele.id}`)
                        }}
                        className={`rounded-full ${areaKey == ele.id ? "bg-yellow-300" : ''} m-2 p-2 px-4 cursor-pointer  border-1 border-yellow-300 flex justify-center items-center`} >
                        <h1 className={`font-bold  ${areaKey == ele.id ? "dark:text-fontDark" : "dark:text-fontWhite"}  text-sm`} >
                          {
                            ele.name
                          }
                        </h1>
                      </div>

                    ))
                  }
                </div>

              }
              </div>

            </div>
          </div>
          :
          <>
            <div className='w-screen flex items-center justify-center h-500'  >
              <CircularProgress className='text-yellow-500' />
            </div>
          </>


      }

    </>
  )
}
