import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ApiService from "../../../Services/apiService";
import { Helmet } from "react-helmet";
import AppConfig from "../../../AppConfig";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Addbanner from "../../Common/Addbanner";
// import vi from "date-fns/locale/vi";

function Videonews() {
  // const currentTheme = window.localStorage.getItem("theme");
  const [videonews, setVideonews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videocontrol, setVideocontrol] = useState(false);
  const [overlaykey, setOverlaykey] = useState(0)



  useEffect(async () => {
    window.scroll(0, 0);
    setLoading(false);
    await ApiService.getVideoapi().then((res) => {
      setVideonews(res?.data?.data);
    });
  }, []);

  return (
    typeof videonews !== undefined && (
      <>
        {!loading && (
          <Helmet>
            <title>{`${AppConfig.siteName} - ${videonews[0]?.title}`} </title>
            <meta
              name="description"
              content={videonews[0]?.category.meta_description}
            />
            <meta property="og:site_name" content={`${AppConfig.siteName}`} />
            <meta
              property="og:title"
              content={`${videonews[0]?.title}-${AppConfig.siteName}`}
            />
            <meta property="og:type" content="Website"></meta>
            <meta
              property="og:url"
              content={`${AppConfig.siteName} - params?.slug`}
            />
            <meta property="og:image" content={videonews[0]?.ImageSrc} />
          </Helmet>
        )}
        {loading ? (
          <>
            <div
              id="videodiv"
              style={{ backgroundColor: "#FFF", flex: "3" }}
              className={`dark:bg-darkBackground ${window.screen.availWidth < 1024 && "mt-2"
                }  cursor-pointer h-500 flex  justify-center items-center  overflow-y-auto  `}
            >
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#f89c1d"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          </>
        ) : (
          <>
            <div id="videodiv" className="w-12/12 px-10">
              <Addbanner id1={1} height="250px" width='100%' />
              <div className="">
                {videonews.map((videodata, key) => {
                  return (
                    <div className="border rounded-lg mx-2 my-5 hover:scale-90 shadow-2xl p-3 hover:shadow-dark ">
                      <div className="flex">
                        <div id="videocontrols" className="my-4 w-1/2">

                          <video
                            width="100%"
                            height="600"
                            onMouseEnter={() => {
                              setVideocontrol(true);
                              setOverlaykey(key)
                            }}
                            onMouseLeave={() => {
                              setVideocontrol(false);
                            }}
                            className="h-64 w-full"
                            controls={overlaykey === key ? videocontrol ? true : false : ""}
                          >
                            <source src={videodata.VideoSrc} type="video/mp4" />
                          </video>
                        </div>
                        <div className="mx-2 my-10 w-1/2 relative ">
                          <div className="dark:text-fontWhite capitalize font-medium text-xl ">
                            {videodata.title}
                          </div>
                          <div className="text-base">
                            {videodata.short_description}
                          </div>
                          <div className="text-right cursor-pointer hover:underline">
                            <a href={videodata.source}>  Read More </a>

                          </div>

                          <div className="flex justify-between absolute bottom-0 w-full ml-auto px-3">
                            {/* <div className="uppercase">{videodata.category_name.name}</div> */}
                            <div>{videodata.created_at.split('T')[0]}</div>
                          </div>

                        </div>

                      </div>
                      <div className="flex justify-between">
                      <div className="w-1/2 flex md:flex-row flex-col h-full justify-evenly my-2 items-center">
                          <div className="text-darkBackground text-base font-semibold">Share On:-</div>

                          <div className="flex w-1/3 justify-between">
                            <a href="https://www.facebook.com/login/?privacy_mutation_token=eyJ0eXBlIjowLCJjcmVhdGlvbl90aW1lIjoxNjYyMDA1NzU4LCJjYWxsc2l0ZV9pZCI6MjY5NTQ4NDUzMDcyMDk1MX0%3D" target='_blank'> <FacebookIcon /> </a>
                            <a href="https://www.instagram.com/accounts/login/?source=auth_switcher" target='_blank'>  <InstagramIcon /> </a>
                            <a href="https://twitter.com/i/flow/login" target='_blank'> <TwitterIcon />  </a></div>

                        </div>
                    
                      <div className="text-right mx-4 my-auto">
                        {/* - By {videodata.author.first_name} */}
                      </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </>
    )
  );
}

export default Videonews;
