const AppConfig = {
  color: {
    primary: "#d8ba25",
    secondary: "#a5975b",
    font: "#333333",
    background: "#fafafa",
    darkBackground: "#022530",
    button: "#9b7c4e",
  },
  breakpoints: {
    mobile: 640,
    tablet: 767,
    desktop: 1300,
  },
  gatewayUrl: "",
  pusher: {
    PUSHER_APP_ID: 1413910,
    PUSHER_APP_KEY: "87909e984abafa89a22c",
    PUSHER_APP_CLUSTER: "ap2",
  },
  // apiUrl: " https://newsnox.thelocalsamachar.com/api/",
  // apiUrl : "https://news-backend.servepratham.com/api/",
  apiUrl : "https://backend.newsnox.com/api/",
  siteName: "Newsnox",
  subscribe : "newsnox",
  appName: "ecommerce",
  currencyType: "US$",
  localAppKey: "access_token",
  localSessionIdKey: "session_id",
  localIdKey: "user_id",
  copyright: "© 2022 Newsnox",
  echoURL: "",
  routes: {
    home: "/home/:slug",
    login: "/login",
    footer: "/footer",
    aboutus: "/about-us",
    Cms: "/cms/:type",
    ViewAll: "/view-all/:type",
    newsDetail: "/detail/:category/:categoryId/:slug/:id",
    videonews: "/videonews",
  },
};

const firebaseConfig = {
  apiKey: "AIzaSyAuVsxMtmURgWe-o5oS9dh52xJREorvnzg",
  authDomain: "local-samachar-d619d.firebaseapp.com",
  databaseURL: "https://local-samachar-d619d.firebaseio.com",
  projectId: "local-samachar-d619d",
  storageBucket: "local-samachar-d619d.appspot.com",
  messagingSenderId: "641613225476",
  appId: "1:641613225476:web:69f32317506014a35f5f51",
  measurementId: "G-EPQMVL8EB2",
};

export default AppConfig;
