import React, { useEffect, useState } from 'react'
import HomeIcon from '@mui/icons-material/Home';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import { LightMode, ModeNight } from "@mui/icons-material";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Switch } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import switchOn from '../../../../../src/Assets/images/switch.png'
import switchOff from '../../../../../src/Assets/images/off-button.png'
import store from '../../../../Redux/Store';
import { ActionTypes } from '../../../../Redux/Contants/ActionType';
import { useSelector } from 'react-redux';
export default function DanikBhaskarHeader(props) {


  const theme = window.localStorage.getItem('theme')
  const location = useLocation()
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const headerArray = ['Home', 'Video', 'Search', 'Dark mode']
  const [activeIcon, setActiveIcon] = useState(0)
  const history = useHistory()
  const cms_data = useSelector(({ allCategories }) => allCategories.cmsData);
  
  
  const [scrollTopHeight, setScrollTopHeight] = useState('')

  useEffect(() => {

    window.addEventListener('scroll', function () {

      setScrollTopHeight(document.documentElement.scrollTop)
    })


  }, [scrollTopHeight])

  const [checked, setChecked] = useState(true)
  return (


    <div className={`w-full ${scrollTopHeight > 64 && window.screen.availWidth <= 640 ? "hidden" : "flex"} fixed top-0 z-10   h-20 border-b  dark:bg-darkBackground bg-whiteBackground border-yellow-500  px-3 mx-auto  shadow-md  justify-between items-center`} >

      {window.screen.availWidth >= 640 ?

        <div className='flex w-full  justify-evenly items-center' >
          <div
            onClick={() => {
              history.push('/')
              window.location.reload()
            }}
            className="flex  items-center cursor-pointer">
            <HomeIcon
              className="dark:text-fontWhite "
              onClick={() => {
                setActiveIcon(4)
              }}
              style={{ color: activeIcon == 0 ? '#f89c1d' : 'black' }}
            />
            <h1
              onClick={() => {
                
                setActiveIcon(0)
               
              }}
              style={{ color: activeIcon == 0 ? '#f89c1d' : 'black' }}
              className='text-sm  dark:text-fontWhite mx-2  uppercase '>Home</h1>
          </div>
          <div className="flex items-center cursor-pointer" onClick={() => {
            history.push('/videonews')
          }}>
            <PlayCircleOutlinedIcon
              className="dark:text-fontWhite"
              onClick={() => {
                setActiveIcon(1)
              }}
              style={{ color: activeIcon == 1 ? '#f89c1d' : 'black' }}
            />
            <h1
              onClick={() => {
                store.dispatch({
                  type: ActionTypes.SEARCH_DATA,
                  payload: true,
                })
                setActiveIcon(1)
              }}
              style={{ color: activeIcon == 1 ? '#f89c1d' : 'black' }}
              className='text-sm dark:text-fontWhite  mx-2 uppercase '>Video</h1>
          </div>
          <div className="flex items-center cursor-pointer"  onClick={() => {
              history.push('/')
              window.location.reload()
            }}>
            <img
              onClick={() => {
                history.push('/')
                store.dispatch({
                  type: ActionTypes.SEARCH_DATA,
                  payload: true,
                })
                setActiveIcon(0)
              }}
              className='w-32 h-20 object-contain' src={require('../../../../Assets/logo.jpg')} />
          </div>
          <div
            onClick={() => {
              store.dispatch({
                type: ActionTypes.SEARCH_DATA,
                payload: false,
              })
              history.push('/search_news')
            }}
            className="flex items-center cursor-pointer">
            <SearchOutlinedIcon
              onClick={() => {
                setActiveIcon(3)
              }}
              className="dark:text-fontWhite"
              style={{ color: activeIcon == 3 ? '#f89c1d' : 'black' }}
            />
            <h1
              onClick={() => {
                setActiveIcon(3)
              }}
              style={{ color: activeIcon == 3 ? '#f89c1d' : 'black' }}
              className='text-sm   dark:text-fontWhite mx-2 uppercase '>Search</h1>
          </div>
          {props.currentTheme === "dark" ? (
            <LightMode
              className=" dark:text-lightsky cursor-pointer"
              onClick={() => {
                props.setCurrentTheme("white")
                window.location.reload()
              }
              }
            />
          ) : (
            <ModeNight
              className=" text-black cursor-pointer"
              onClick={() => {
                props.setCurrentTheme("dark")
                window.location.reload()
              }
              }
            />
          )}
        </div>
        :
        <>
          <div className={`${document.documentElement.scrollTop > 64 ? "none" : "block"}`}>
            <img
              onClick={() => {
                history.push('/')
              }}
              className='w-24 h-20 object-contain' src={require('../../../../Assets/logo.jpg')} />
          </div>
          <div className="flex items-center cursor-pointerjustify-between">
            <MoreVertOutlinedIcon
              onClick={() => props.setOpenFooterModal(true)}
              className="dark:text-fontWhite mx-2" />
          </div>
          {
            props.openFooterModal &&

            <div id="footerDrawer" className='absolute w-40 h-auto p-3  shadow-md border-1 border-gray-200 right-4 top-16 bg-whiteBackground z-10 dark:bg-darkBackground'  >

            {cms_data.map((data) => {
              return <div className='dark:text-fontWhite text-sm my-2'>{data.title}</div>
            })}
              {/* {/* <h1 className='dark:text-fontWhite text-lg my-2' >Contact Us</h1> */}
              {/* <h1 className='dark:text-fontWhite text-lg my-2' >Privacy Policy</h1>  */}
              <div className='flex justify-between items-center my-2' >

                <div className='dark:text-fontWhite text-lg  ' >
                  {
                    theme == 'dark' ? "Light mode" : "Dark mode"
                  }
                </div>
                <img

                  style={{
                    filter: theme == 'dark' ? 'invert(100%)' : ''
                  }}

                  onClick={() => {
                    if (props.currentTheme == 'dark') {


                      props.setCurrentTheme("white")
                    }
                    else {
                      props.setCurrentTheme('dark')
                    }
                    window.location.reload()

                  }
                  }
                  src={theme == 'dark' ? switchOn : switchOff} className="w-10 cursor-pointer h-10" />
              </div>
              <h1 className='dark:text-fontWhite text-lg my-2' >Advertise with us</h1>


            </div>
          }
        </>
      }

    </div>
  )
}
