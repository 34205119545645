const sp = {
  translation: {
    header: {
      search: "Buscar...",
      language: "La Lengua",
    },
    productDetails:{addToWishList:' Añadir a la lista de deseos',viewShop:"Ver tienda",chatWithUs:"habla con nosotros"},
    myOrders:{filters:"filtros",orderStatus:"Estado del pedido",onTheWay:"En camino",delivered:"Entregada",cancelled:"cancelada",returned:"devuelto",orderTime:"Tiempo de la orden",last30Days:"Últimos 30 días",older:"Más viejo",soldBy:"Vendido por",viewOrder:"Ver pedido",cancelOrder:"cancelar orden",address:'Dirección',paymentDetail:"Detalles de pago"},
    card: { button: { addToCart: "Añadir Al Carrito",productDetail:"detalle del producto" } },
    shop:{categoryHeading:'categorias',showingHeading:'Demostración',size:'Talla',price:'Precio',brand:'Marca',discount:' Descuento',andMore:"y más",color:'color'},
    home: {
      button: { viewAll: "Ver todo" },
      categories: { title: "Categorías" },
      
      brand: { title: "Comprar Por Marcas" },
      fCollection: { title: "Colección Destacada" },
      flashSale: { title: "venta express" },
      trProducts: { title: "Productos de Tendencia" },
      topProducts: { title: "Productos principales" },
      nearbySellers: { title: "Vendedores cercanas" },

    


     
      providingFeatures: {
        i: {
          main: "Envío y devolución gratis",
          secondary: "Envío gratuito en todos los pedidos de EE. UU.",
        },
        ii: {
          main: "Garantía de dinero",
          secondary: "Garantía de devolución de dinero de 30 días",
        },
        iii: {
          main: "Soporte en línea",
          secondary: "Apoyamos en línea 24/7 en el día",
        },
        iv: {
          main: "Pagos Seguros",
          secondary: "Todos los pagos son seguros y confiables.",
        },
      },
    },
  },
};

export default sp;
