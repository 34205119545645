import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import News from "../News";
import AppConfig from "../../../../AppConfig";
import ApiService from "../../../../Services/apiService";
export default function NewsByCategory() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    ApiService.getNewsCategories().then((res) => {
      setCategories(res?.data?.CategoriesDetails);
      console.log(res.data.CategoriesDetails);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {!loading &&  <Helmet>
        <title>{`${AppConfig.siteName} - ${categories[0]?.meta_title}`} </title>
        <meta
          name="description"
          content={`${categories[0]?.short_description}`}
        />
        <meta property="og:site_name" content={`${AppConfig.siteName}`} />
        <meta
          property="og:title"
          content={`${categories[0]?.meta_title}-${AppConfig.siteName}`}
        />
        <meta property="og:type" content="Website"></meta>
        <meta
          property="og:url"
          content={`${AppConfig.siteName} - ${categories[0].meta_tag}`}
        />
        <meta property="og:image" content={categories[0]?.ImageSrc} />
      </Helmet>}
      <div
        id="newsContainer"
        className="flex relative flex-col sm:flex-row h-auto w-12/12 "
      >
        <News data={categories} />
      </div>
    </>
  );
}
