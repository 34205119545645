const en = {
  translation: {
    alertComponent: {
      otherSellerProductsinCart:
        "Your cart contains products from other seller please romove the cart",
      alert: "Alert",
      removeOtherAddThis: "Remove other items and add this",
      cancel: "Cancel",
      ok:'Ok',
      only10Products:"You can Only add ten product"
    },
    productcard: {
      addtowishlist: "Add to wishlist",
      removefromwishlist: "Remove from wishlist",
    },
    header: {
      search: "Search...",
      language: "Language",
      categories: "Categories",
      home: "Home",
      alert: "Alert",
      stockNotAvailable: " Stock Not Available....",
      ok: "Ok",
      profile: "Profile",
      myChats: "My chats",
      myFollowing: "My following",
      logout: "Log Out",
      myCart: "My cart",
      myOrders: "My orders",
      wishlist: "Wishlist",
      notifications: "Notifications",
      cart: "Cart",
      login: "Login",
      account: "Account",
    },
    paymentForm: {
      paymentOptions: " Debit Card, Credit Card,Net Banking, UPI",
      cashOnDelivery: "Cash on delivery",
    },
    checkOutForm: {
      payNow: "Pay now",
    },
    addressForm: {
      city: "City",
      state: "State",
      locality: "Locality",
      shippingAddress: "Shipping address",
      addAddress: "Add address",
      name: "Name",
      mobile: "Mobile",
      landmark: "Landmark",
      pinCode: "PinCode",
      addressType: "Address_type",
      home: "Home",
      office: "Office",
      address: "Address",
      country:"Country"
    },
    noFound: {},
    order: {
      shippingAddress: "Shipping Address",
      mobile: "Mobile",
      deliveryAt: "Delivery at",
      orderDetails: "Order Details",
      qty: "Qty",
      status: "Status",
      paymentMethod: "Payment Method",
      orderedOn: "Ordered on",
      customerDetails: "Customer details",
      trackOrder: "Track Order",
      backToOrder: "Back to Order",
    },

    cancelOrder: {
      reasonForCancel: "Reason for cancellation",
      orderCancellation: "Order Cancellation",
      orderCancel: "Order cancel",
      continue: "Continue",
      itemInfo: "Item information",
      qty: "Qty",
      deliveryFee: "Delivery Fee",
      orderDiscount: "Order Discount",
      productAmount: "Product Amount",
      price: "Price",
      comments: "Comments",
    },
    footer: {
      quickShop: "Quick Shop",
      informations: "Informations",
      customerService: "Customer Service",
      contactUs: "Contact Us",
    },
    mobileFooter: {
      home: "Home",
      category: "Category",
      new: "New",
      me: "me",
      bag: "Bag",
      myOrders: "My orders",
      categoriesDrawer: {
        categories: "Categories",
      },
    },
    common: { addToCart: "Add to cart" },
    card: {
      button: { addToCart: "Add to Cart", productDetail: "View Details" },
    },
    shop: {
      home: "Home",
      mobileDisplay: "Mobile display",
      categoryHeading: "Categories",
      showingHeading: "Showing",
      size: "size",
      price: "Price",
      brand: "Brand",
      discount: "Discount",
      andMore: "and more",
      color: "Color",
      filter: "Filters",
      allFilter: "All Filters",
      applyAllFilters: "Apply all filters",
      clear: "Clear",
      more: "more",
      productFilters: "Product Filters",
      noFilters: "No filters",
      showingProducts: "Showing Products",
      products: "Products",
      of: "of",
      noProductFound: "No product found",
      noProducts: "No products",
    },
    productDetails: {
      all: "all",
      reviews: "reviews",
      one: "One",
      two: "Two",
      three: "Three",
      four: "Four",
      five: "Five",
      soldBy: "Sold By",
      outOfStock: "Out of stock",
      addToWishList: "Add to Wishlist",
      viewShop: "View Shop",
      chatWithUs: "Chat With Us",
      ratings: "Ratings",
      followers: "Followers",
      products: "Products",
      productDetails: "Product Details",
      productRatingAndReviews: "Product Ratings & Reviews",
      similarProducts: "Similar Products",
      reviews: "Reviews",
      removeFromWishlist: "Remove from Wishlist",
      share: "Share",
    },
    myOrders: {
      filters: "Filters",
      orderStatus: "Order Status",
      onTheWay: "On the Way",
      delivered: "Delivered",
      cancelled: "Cancelled",
      returned: "Returned",
      orderTime: "Order Time",
      last30Days: "Last 30 Days",
      processing: "Processing",
      older: "Older",
      soldBy: "Sold By",
      viewOrder: "View Order",
      cancelOrder: "Cancel Order",
      address: "Address",
      paymentDetail: "Payment Detail",
      COd: "COD",
      transactionId: "Transaction ID ",
      myOrders: "My Orders",
      filter: "Filters",
      openMainMenu: "Open main menu",
      orderedOn: "Ordered On",
      orderAmount: "Order Amount",
      qty: "Qty",
    },
    myProfile: {
      profile: "Profile",
      alert: "Alert",
      ok: "ok",
      updateProfile: "Update Profile",
      firstName: "First Name",
      city: "City",
      email: "Email Address",
      phone: "Phone Number",
      state: "State",
      address: "Address",
    },
    providingFeatures: {
      features: {
        i: {
          heading: "Safe payment",
          subHeading: "Pay with the world's most safe payment methods",
        },
        ii: {
          heading: "Confidence",
          subHeading: "Protection covers your purchase and personal data",
        },
        iii: {
          heading: "WORLDWIDE DELIVERY",
          subHeading: "FREE & fast shipping to over 200+ countries & regions.",
        },
        iv: {
          heading: "HOTLINE",
          subHeading:
            "Talk to help line for your question on 4141 456 789, 4125 666 888",
        },
      },
    },
    cartDetails: {
      product: "Product",
      quantity: "Quantity",
      total: "Total",
      price: "Price",
      shopNow: "Shop Now",
      noItems: "No items in the cart",
      checkout: "CheckOut",
      subtotal: "Sub Total",
      discount: "Discount",
      shipping: "Shipping",
      yourCart: "Your Cart",
      addOnlyTenProducts: "You can only add ten products",
      action: "Action",
      ok: "ok",
    },

    checkout: {
      alert: "Alert",
      ok: "Ok",
      checkout: "Check out",
      thankYouForOrder: "Thank you for order",
      orderNumberIs: "Order number is",
      transactionIdIs: "Transaction id is",
      continueShopping: "Continue shopping",
      back: "Back",
      next: "Next",
      placeOrder: "Place order",
      payNow: "Pay now",
    },
    review: {
      minimumOrderValue: "Minimum order value is",
      maimumDiscountIS: "Maximum discount is",
      orderSummary: "Order summary",
      productName: "Product Name",
      originalPrice: "Original price",
      offerPrice: "Offer price",
      orderAmount: "Order amount",
      offerDiscount: "Offer discount",
      total:"Total",
      subTotal: "Sub Total",
      Qty: "Qty",
      productAmount: "Product Amount",
      deliveryFee: "Delivery fee",
      marginPrice: "Margin Price",
      orderDiscount: "Order discount",
      orderTotal: "Order total",
      chooseCoupon: "Choose coupon",
      remove: "Remove",
      applyCoupon: "Apply coupon",
      youSaveAdditional: "You save additional",
      shipping: "Shipping address",
      paymentMethod: "Payment method",
    },

    wishList: {
      addToCart: "Add to Cart",
      product: "Product",
      offerPrice: "Offer Price",
      price: "Price",
      yourWishlist: "My Wishlist",
      addOnlyTenProducts: "You can only add ten products",
    },
    attributeModal: {
      price: "Price",
      chooseOptions: "Choose Options",
      noProducts: "There are no item in wishlist",
      shopNow: "Shop now",
      addToCart: "Add to cart",
      alert: "Alert",
      ok: "Ok",
    },
    chats: {
      chats: "Chats",
      noChats: "You dont have any chats",
      noThanks: "No thanks",
      upgradeNow: "Upgrade now",
      makeAOffer: "Make a offer",
      acceptOffer: "Accept offer",
      am: "am",
      pm: "pm",
      addToCart: "Add to cart",
      noChats: "You dont have any chats",
    },
    login: {
      loginWithPhone: "Login with phone",
      loginWithEmail: "Login with email",
      login: "Login",
      newUser: "      New user ? Create an account",
      sendOtp: "Send OTP",
      login: "Login",
      or: "Or",
      resendOtp: "Resend the OTP",
      enterPhone: "Enter your phone number",
      dialingCode: "dialing_code",
      alreadyAccount: "Already have an account ? Login",
      verify: "Verify",
      signUp: "sign Up",
      enterTheOtp: "Please enter the otp",
      password: "Enter the password",
      emailPhone: "Email/Phone",
      email: "Enter the email",
      name: "Enter your name",
    },
    pageNotFound: {
      oops: "    oops! page not found",
      unAvailable:
        "    The page you are trying to access might have been removed, changed, or is unavailable.",
      goToHome: "   Go to home page",
      tryExploring: "    Try Exploring",
    },
    vendorDetails: {
      myFollowing: "My Following",
      vendorDetails: "Vendor Details",
    },
    profileMenu: {
      profile: "Profile",
      myChats: "My Chats",
      myOrders: "My Orders",
      myCart: "My Cart",
      myFollowing: "My Following",
      logout: "Logout",
    },
    chatPopUp: {
      actualPrice: "Actual Price",
      makeAOffer: "Make a Offer",
      am: "am",
      pm: "pm",
      acceptOffer: "Accept offer",
      send: "Send",
      addToCart: "Add to cart",
    },
    sortForm: {
      newArrivals: "New Arrivals",
      mostPopular: "Most Popular",
      priceLowToHigh: "price(low to high)",
      priceHighToLow: "price(high to low",
      ratingHighToLow: "  Ratings(High to Low)",
    },
    myFollowing: {
      ratings: "Ratings",
      followings: "Followings",
      products: "Products",
      allProducts: "All products",
      showing: "Showing",
      follow: "Follow",
      following: "Following",
    },
    home: {
      button: { viewAll: "View All" },
      categories: { title: "Categories" },
      brand: { title: "Shop by Brands" },
      fCollection: { title: "Featured Products" },
      flashSale: { title: "Flash Sale", left: "Left" },
      trProducts: { title: "Trending Products" },
      topProducts: { title: "Top Products" },
      nearbySellers: { title: "Nearby Sellers" },
      providingFeatures: {
        i: {
          main: "Free Shipping & Return",
          secondary: "Free shipping on all US orders",
        },
        ii: {
          main: "Money Guarantee",
          secondary: "30 days money back guarantee",
        },
        iii: {
          main: "Online Support",
          secondary: "We support online 24/7 on day",
        },
        iv: {
          main: "Secure Payments",
          secondary: "All payment are Secured and trusted",
        },
      },
    },
  },
};
export default en;
