
import { initializeApp } from "firebase/app";
// import { getMessaging } from "firebase/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import AppConfig from "../../AppConfig";
import http from "../../Utils/http";
import ApiService from "../apiService";


var onMessageListener;
// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAuVsxMtmURgWe-o5oS9dh52xJREorvnzg",
  authDomain: "local-samachar-d619d.firebaseapp.com",
  databaseURL: "https://local-samachar-d619d.firebaseio.com",
  projectId: "local-samachar-d619d",
  storageBucket: "local-samachar-d619d.appspot.com",
  messagingSenderId: "641613225476",
  appId: "1:641613225476:web:69f32317506014a35f5f51",
  measurementId: "G-EPQMVL8EB2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const messaging = getMessaging(app);

getToken(messaging, { vapidKey: 'BDlyG0dnSGiN7t_38LVl2204KAPxIS_kEC3xgPTPahIdKE3CuZXrhnwS4a_TEFp8x77M-7AxSIA8OWE67LKw0mk' }).then((currentToken) => {
  if (currentToken) {
    console.log(currentToken)
    ApiService.getConfig().then((res) => {
      console.log()
      let formData = new FormData();
      formData.append("token", currentToken);
      formData.append('topic', `${res.data.PREFIX_ID}_newsnox`)   
      http.post("/user/subscribe", formData).then((response) => {
        // console.log(response)
      });
    })
  } else {
    console.log('No registration token available. Request permission to generate one.');

  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});

onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export { getToken, onMessageListener }