import React, { useEffect, useState } from "react";
import ApiService from "../../Services/apiService";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Scriptadd from "./Scriptadd";

function Addbanner({ id, id1, height, width }) {
  // alert(id1, id)
  const banner = useSelector(({ allCategories }) => allCategories.adsbanner);
  // console.log(banner, 'banner');
  id1 = parseInt(id1);
  const location = useLocation();
  // console.log( id1,  id2)
  const [addbanner, setAddbanner] = useState(banner);

  useEffect(() => {
    // alert(id1 , "id1")
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        // console.log({ adsbygoogle });
        adsbygoogle.push({});
      } catch (e) {
        // console.error(e);
      }
    };

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    typeof addbanner !== undefined && (
      <>
        <div className=" my-4">
          {addbanner?.map((data, key) => {
            if (
              data.positions.id == id1 + 20 ||
              data.positions.id == id1 + 10 ||
              data.positions.id == id1 ||
              key == id
            ) {
              if (data.type == "Image") {
                return (
                  <div className={` border  my-2 text-center`}>
                    {/* {console.log(data.positions.id)} */}
                    <div>Advertisement</div>
                    <div className="flex justify-center"><img
                      style={{ height: height, width: width }}
                      src={data.ImageSrc}
                      onClick={() => window.open(data.redirect_url, "_blank")}
                      className=" cursor-pointer"
                    />
                    </div>
                  </div>
                );
              } else if (data?.type === "Video") {
                return (
                  <div className="relative max-w-full  my-2">
                    <div className="text-center">Advertisement</div>
                    <div className="flex justify-center ">
                    <iframe
                      style={{ height: height, width: width }}
                      src={data.src_url}
                      onClick={() => window.open(data.redirect_url, "_blank")}
                      frameBorder="0"
                      allow="autoplay;"
                      allowFullScreen
                      title="video"
                    />
                    </div>
                  </div>
                );
              } else if (data?.type === "Script") {
                return (
                  <div className="my-2" style={{ height: "250px" }}>
                    {/* <Scriptadd/> */}
                  </div>
                );
              }
            }
          })}
        </div>
      </>
    )
  );
}

export default Addbanner;
