export const ActionTypes = {
  ADD_CATEGORIES: "ADD_CATEGORIES",
  ADD_SESSION_ID: "ADD_SESSION_ID",
  ADD_USER_DATA: "ADD_USER_DATA",
  ADD_USER_CART_COUNT: "ADD_USER_CART_COUNT",
  ADD_USER_WISHLIST_COUNT: "ADD_USER_WISHLIST_COUNT",
  ADD_USER_CART_ITEM: "ADD_USER_CART_ITEM",
  ADD_USER_Notification_COUNT: "ADD_USER_Notification_COUNT",
  ADD_CMS: "ADD_CMS",
  USER_CART_ITEMS: "USER_CART_ITEMS",
  INFINITE_SCROLL: "INFINITE_SCROLL",
  NEWS_DATA: "NEWS_DATA",
  LOAD_MORE_NEWS: "LOAD_MORE_NEWS",
  ADS_BANNER: "ADS_BANNER",
  SEARCH_DATA:"SEARCH_DATA"
};
