import { PanoramaWideAngleSelect } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ApiService from '../../../../Services/apiService'
import AdvertisementSection from '../AdvertisementSection'
import CategorySection from '../CategorySection'
import Categories from './Categories'
import NewsDetailSection from './NewsDetailSection'
import { Helmet } from 'react-helmet'
import AppConfig from '../../../../AppConfig'
import { ThreeDots } from 'react-loader-spinner'
export default function NewsDetail() {
  const [newsDetail, setNewsDetail] = useState([])
  const [similarNews, setSimilarNews] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()
  useEffect(() => {
    window.scroll(0, 0)
    const form = new FormData()
    form.append('news_id', params.id)
    ApiService.getNewsDetail(form).then((res) => {
      setNewsDetail(res.data.NewsDetails)
      setSimilarNews(res.data.related_news)
      setLoading(false)
    })


  }, [params])

  const history = useHistory()

  return (
    typeof newsDetail !== undefined && ( 
    <>
      {!loading &&
        <Helmet>

          <title>{`${AppConfig.siteName} --- ${newsDetail?.title}`} </title>
          <meta name="description" content={newsDetail[0]?.short_description} />
          <meta property="og:site_name" content={`${AppConfig.siteName}`} />
          <meta property="og:title" content={`${newsDetail?.title}-${AppConfig.siteName}`} />
          <meta property="og:type" content="Website"></meta>
          <meta property="og:url" content={`${AppConfig.siteName} - ${params?.slug}`} />
          <meta property="og:image" content={newsDetail?.ImageSrc} />

        </Helmet>}

      <div className=' my-2 md:w-full w-screen' >
        {window.screen.availWidth >= 640 &&
          <div id="breadCrumbDiv" className=' px-10 md:justify-start justify-center flex items-center' >
            <div>
              <span onClick={() => {
                history.push('/')
              }} className='mx-2 cursor-pointer dark:text-fontWhite text-fontDark text-sm ' >
                Home                      /
              </span>
              <span
                onClick={() => {
                  // setCategoryId(key)
                  window.scroll(0, 0)
                  history.push(`/category/${params.category}/${params.categoryId}`)
                }}
                className='mx-2 cursor-pointer dark:text-fontWhite text-fontDark text-sm ' >
                {params.category}            /
              </span>
              <span className='mx-2 cursor-pointer  dark:text-fontWhite text-fontDark text-sm ' >
                {params.slug.split('-').join(' ')}


              </span>
              
            </div>

          </div>}
        {
          loading ?
            <div style={{ flex: '2' }} className='flex sm:px-0 px-2 h-40 items-center justify-center    ' >
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color='#f89c1d'
                ariaLabel='three-dots-loading'
                wrapperStyle
                wrapperClass
              />
            </div>
            :
            <div
              id="newsContainer" className='flex relative sm:flex-row flex-col h-auto justify-center sm:mt-5 sm:py-3' >
              {window.screen.availWidth <= 640 &&
                <div id="breadCrumbDiv" className='w-screen p-2 my-2 mt-4 md:mx-8 mx-4 flex justify-center items-center' >
                  <h1>
                    <span onClick={() => { history.push('/') }}
                      className='mx-2  cursor-pointer dark:text-fontWhite text-fontDark text-sm ' > Home </span>
                    <span className='mx-2 cursor-pointer dark:text-fontWhite text-fontDark text-sm ' >
                      /
                    </span>
                    <span
                      onClick={() => {
                        // setCategoryId(key)
                        window.scroll(0, 0)
                        history.push(`/category/${params.category}/${params.categoryId}`)
                      }}
                      className='mx-2 cursor-pointer dark:text-fontWhite text-fontDark text-sm ' >
                      {params?.category}            /
                    </span>
                    <span className='mx-2 dark:text-fontWhite text-fontDark text-base ' >
                      /
                    </span>
                    <span className='mx-2  dark:text-fontWhite text-fontDark text-base ' >
                      {params.slug.split('-').join(' ')}
                    </span>
                  </h1>

                </div>
              }
              <NewsDetailSection newsDetail={newsDetail} similarNews={similarNews} loading={loading} />
            </div>
        }
      </div>
    </>
    )
  )
}
