import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import AppConfig from '../AppConfig';

export default function Cmsdata() {

  const [loading, setLoading] = useState(true)
    const location = useLocation();
   const cmsapidata = location.state
   console.log(cmsapidata)
   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {!loading &&
        <Helmet>
          <title>{`${AppConfig.siteName} - ${cmsapidata.brandName.meta_title}`} </title>
          <meta name="description" content={cmsapidata.brandName.meta_description} />
          <meta property="og:site_name" content={`${AppConfig.siteName}`} />
          <meta property="og:title" content={`${cmsapidata.brandName.meta_title}-${AppConfig.siteName}`} />
          <meta property="og:type" content="Website"></meta>
          <meta property="og:url" content={`${AppConfig.siteName} - params?.slug`} />
          <meta property="og:image" content='Image' />

        </Helmet>} 
    <div className='px-7 mt-10 container'>
       <div className='text-xl px-3 font-semibold py-3'>{cmsapidata.brandName.title}</div> 
       <div className='px-4'>{ReactHtmlParser(cmsapidata.brandName.description)}</div>
    </div>
    </>
  )
}
