import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import branch from "branch-sdk";
import React, { useEffect, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Assets/css/style.css";
import Routing from "./Routing";
import Message from "./CoreComponents/Message";
import apiService from "./Services/apiService/apiService";
import "./styles/Custom.css";
import "./styles/output.css";
import http from "./Utils/http";
import { Helmet } from "react-helmet";
import AppConfig from "./AppConfig";
import { getMessaging, getToken } from "firebase/messaging";
import app from "./Services/Firebase/Firebase";
import messaging from "./Services/Firebase/Firebase";
import messag from "./Services/Firebase/Firebase";
import { useSelector } from "react-redux";
import ApiService from "./Services/apiService";
import store from "./Redux/Store";
import { ActionTypes } from "./Redux/Contants/ActionType";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Nunito",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

function App() {
  const cmsData = useSelector(({ allCategories }) => allCategories.cmsData);
  sessionStorage.setItem('searchKey', true)
  // console.log(cmsData, "cmsdata ")
  const [loading, setLoading] = useState(false);
  const [foreground, setForeground] = useState(null);
  // const [isTokenFound, setTokenFound] = useState(false);
  const currenttheme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(currenttheme);
  useEffect(() => {
    ApiService.getaddbanner().then((res) => {

    });
     ApiService.getCmsData().then((res) => {
        if (res.code === 200) {
          // setCmsdata(res.data.cms_pages);
          store.dispatch({
            type: ActionTypes.ADD_CMS,
            payload: res.data.cms_pages,
          });
        }
      });
      
    if (currenttheme == null || currenttheme == "null") {
      setCurrentTheme("white");
      localStorage.setItem("theme", "white");
    }
    if (currentTheme == "dark") {
      const root = window.document.documentElement;
      root.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else if (currentTheme == "white") {
      const root = window.document.documentElement;
      root.classList.remove("dark");
      localStorage.setItem("theme", "white");
    }
  }, [currentTheme]);

  const [openFooterModal, setOpenFooterModal] = useState(false);
  return (
    typeof cmsData !== undefined && (
      <>
       {!loading && ( <Helmet>
          <title>{`${AppConfig.siteName} -  ${cmsData[0]?.meta_title}`} </title>
          <meta
            name="description"
            content={`${cmsData[0]?.meta_description}`}
          />
          <meta property="og:site_name" content={`${AppConfig.siteName}`} />
          <meta
            property="og:title"
            content={`${cmsData[0]?.meta_title}-${AppConfig.siteName}`}
          />
          <meta property="og:type" content="Website"></meta>
          <meta
            property="og:url"
            content={`${AppConfig.siteName}-${cmsData[0]?.meta_tag}`}
          />
          <meta property="og:image" content="Imagesrc" />
        </Helmet>
)}
        <ThemeProvider theme={theme}>
          <SkeletonTheme
            baseColor="bg-background"
            highlightColor="bg-background"
          >
            <div
              onClick={() => {
                if (openFooterModal) {
                  setOpenFooterModal(false);
                }
              }}
              className="lg:w-screen h-screen  dark:bg-darkBackground"
            >
              <Routing
                setOpenFooterModal={setOpenFooterModal}
                openFooterModal={openFooterModal}
                setCurrentTheme={setCurrentTheme}
                currentTheme={currentTheme}
                foreground={foreground !== null ? foreground : null}
                setForeground={setForeground}
              />
              <Message />
            </div>
          </SkeletonTheme>
        </ThemeProvider>
      </>
    )
  );
}
export default App;

