import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core';
import News from '../News';

export default function NewsDetailSection(props) {
  const useStyles = makeStyles((theme) => ({
    text: {
      "& img": { display: "none" }
    }
  }));
  const classes = useStyles()
  return (
    <>
      {
        typeof props.newsDetail !== undefined && typeof props.newsDetail !== 'undefined ' &&

        <div style={{ flex: '3' }} className='flex md:px-10  px-4 flex-col ' >
          <div className=' mb-7'>
            <div id="newsHeading" className="w-full  my-2 flex flex-col justify-center " >
              <div style={{ lineHeight: "4rem!important" }} className='leading-10 font-light dark:text-fontWhite  text-fontDark lg:text-4xl md:text-5xl  text-2xl my-2' >{props.newsDetail?.title}</div>
              <p className='text-lg mt-2 text-gray-600'>
                <p className={classes.text} >
                  {
                    props.newsDetail?.short_description
                  }
                </p>
              </p>
            </div>
            <div className='w-full object-contain my-3 ' >
              <img  src={props.newsDetail?.ImageSrc} alt="News Details" />
            </div>
            <div className='my-4 p-2 ' >
              <p className='text-lg'>{ReactHtmlParser(props.newsDetail?.description)}..</p>
            </div>
            <div className='font-semibold cursor-pointer px-7 hover:underline text-right'>
              <a href={props.newsDetail?.source} target='_blank' > Read More</a>
            </div>
          </div>
          <div className='mt-3' >
            <div className='w-full flex px-3 items-center' >
              <p className='text-fontDark  dark:text-fontWhite text-lg uppercase my-2 font-bold' >Read More by {props?.newsDetail?.category?.name}</p>
            </div>
           
          </div>
          <News similarNews={props.similarNews} />
        </div>

      }

    </>
  )
}
