import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AppConfig from '../../../AppConfig'

// import SupportChat from '../../../Pages/SupportChat/SupporChat'
export default function FlipkartFooter() {
    const [openChatModal, setOpenChatModal] = useState(false)
    const arr = ['Policies:Returns Policy', 'Terms of use', 'Security', 'Privacy', 'Infringement',]
    const history = useHistory()
    return (
        <div className='w-screen d-flex lg:px-0 px-2 lg:flex-row flex-column justify-evenly h-auto items-center'>
            <div className='flex lg:w-3/5 w-full flex-column lg:flex-row   justify-around' >
                <div className='flex sm:flex-row  flex-column [&>*]: text-fontWhite'>
                    {
                        arr.map((ele) => (
                            <>
                                <h1 className="dark:text-fontWhite lg:mt-0 mt-1 text-gray-700 font-bold sm:mx-1 text-sm">{ele} </h1>
                                <span>    </span>
                            </>
                        ))

                    }
                </div>
                <div>

                    <h1 className='dark:text-fontWhite text-gray-700 font-bold text-sm'>© 2007-2022 {AppConfig.siteName}</h1>

                </div>
            </div>
            <div className='flex w-full lg:w-1/4'>



                <h1 className="dark:text-fontWhite text-gray-700 font-bold mx-1 sm:mt-0 mt-1 text-sm"> Need Help ? Visit the
                    <Link to="/cms/contate-nos">
                        <span
                            onClick={() => {

                                history.push('')
                            }}
                            className=' cursor-pointer mx-0.5 text-lightslate' >
                            Contact us
                        </span>
                    </Link>
                    or


                    <span onClick={() => {
                        setOpenChatModal(true)
                    }} className=' mx-0.5 cursor-pointer  text-lightslate' >
                        Support
                    </span>

                </h1>
            </div>
        </div>
    )
}
