import React, { useEffect, useState } from "react";
import facebook from "../../../../src/Assets/images/facebook.png";
import instagram from "../../../../src/Assets/images/instagram.png";
import twitter from "../../../../src/Assets/images/twitter.png";
import Categories from "./NewsDetail/Categories";
import { useLocation } from "react-router-dom";
import ApiService from "../../../Services/apiService";
import { ThreeDots } from "react-loader-spinner";
import Addbanner from "../../Common/Addbanner";
import '../../../Assets/css/news.css'
import { useSelector } from "react-redux";
import { AlignVerticalTop } from "@mui/icons-material";

export default function CategorySection() {
  const [heightElem, setHeightelem] = useState('')
  const [scrollTopHeight, setScrollTopHeight] = useState("");
  const element = document.getElementById("followUsOn");
  var container = document.getElementById("categoryNewMocs");
  const emptyDivAtTop = document.getElementById("emptyDivAtTop");

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("scroll", function (event) {
        console.log(document.documentElement.scrollTop)
        console.log(document.getElementById("categoryNewMocs").scrollHeight, window.screen.availHeight)
        const finalH = (document.getElementById("categoryNewMocs").scrollHeight - window.screen.availHeight) + 200
        console.log(finalH)
        if (finalH <= document.documentElement.scrollTop) {
          // alert('addes')
          document.getElementById("categoryNewMocs").classList.add('sticky_position') 
          console.log("hello")
          if (document.getElementById("categoryNewMocs").classList.contains('relative_position')) {
            document.getElementById("categoryNewMocs").classList.remove('relative_position')
          }
          // this.alert('addes')

        }
        else 
        if (finalH > document.documentElement.scrollTop) {
          document.getElementById("categoryNewMocs").classList.add('relative_position')
          if (document.getElementById("categoryNewMocs").classList.contains('sticky_position')) {
            document.getElementById("categoryNewMocs").classList.remove('sticky_position')
          }
        }


      });
    }, 1000);
  }, [])

  const categories_data = useSelector(({ allCategories }) => allCategories.Categories);

  const [categories, setCategories] = useState([categories_data]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  return (
    <>
      <div className="">
        {location.pathname.includes("search_news") ? null : loading ? (
          <div className="md:h-auto h-screen md:block flex justify-center">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#f89c1d"
              ariaLabel="three-dots-loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        ) : (
          <div
            id="categoryNewMocs"
            className=" md:grid lg:grid hidden sm:lg:flex-col relative_position z-4 sticky_position dark:bg-darkBackground bg-whiteBackground items-center flex-1  shadow-md"
          >
            <div className="">
              <Categories categories={categories} setCategories={setCategories} />
              <div
                id="mainFollowDiv"
                className=" my-2 w-5/6 flex lg: flex-col justify-center  items-center "
              >
                <div className="  w-52"> <Addbanner id1={2} width='250px' height='200px' /></div>
                <div className="text-base mx-3 dark:text-fontWhite ">
                  Download app from{" "}
                </div>
                <a href="https://play.google.com/store/apps/details?id=com.localsamachar" target={'_blank'}>
                  <img
                    className="my-1 mx-3"
                    src="https://www.bhaskar.com/assets/images/playstore-a.58f8fbd8.svg"
                  />
                </a>
              </div>

              <div className=" my-2 flex  lg: flex-col mx-4 items-center">
                <h1 className="text-base dark:text-fontWhite ">Advertisement </h1>

                <div className=" w-52"> <Addbanner id1={3} width="250px" height='200px' /> </div>
              </div>

              <div className="flex  lg: flex-col w-full items-center">
                <h1 className="text-base  dark:text-fontWhite">Follow us On</h1>
                <div className="flex w-3/4  justify-evenly my-1 items-center">
                  <img className="w-8 h-8" src={facebook} />
                  <img className="w-8 h-8" src={instagram} />
                  <img className="w-8 h-8" src={twitter} />
                </div>
              </div>


            </div>
            <div className="mt-2 hidden" id="followUsOn">
              Hidden div
            </div>
          </div>
        )}
      </div>
    </>
  );
}

