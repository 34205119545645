import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import AppConfig from "./AppConfig";
import Home from "./Pages/Home";
import { Toaster } from "react-hot-toast";
import React, { useEffect, useState } from "react";
import FlipkartFooter from './Components/Common/FlipkartFooter/FlipkartFooter.js'
import DanikBhaskarHeader from './Components/Common/Header/DeskTopHeader/DanikBhaskarHeader'
import NewsDetail from "./Components/HomeSpecificComponents/NewsSection/NewsDetail/NewsDetail";
import NewsByCategory from "./Components/HomeSpecificComponents/NewsSection/NewsByCategory/NewsByCategory";
import Search from "./Components/HomeSpecificComponents/NewsSection/Search";
import CategorySection from "./Components/HomeSpecificComponents/NewsSection/CategorySection";
import Categories from "./Components/HomeSpecificComponents/NewsSection/NewsDetail/Categories";
import AdvertisementSection from "./Components/HomeSpecificComponents/NewsSection/AdvertisementSection";
import Cmsdata from './Components/Cmsdata'
import Videonews from "./Components/HomeSpecificComponents/NewsSection/Videonews";
import '../src/Assets/css/news.css'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useSelector } from "react-redux";


function Routing({ setCurrentTheme, currentTheme, openFooterModal, setOpenFooterModal }) {
  const [viewWidth, setViewWidth] = useState(window.offsetWidth);
  // const [advbanner, setAdvbanner] = useState('')
  const search = useSelector(
    ({ allCategories }) => allCategories.searchData
  );
  useEffect(() => {
    setViewWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setViewWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setViewWidth(window.innerWidth);
      });
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    alert('scroll')
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  
  return (
    typeof advbanner!= undefined && (
     
    <BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            theme: {
              primary: "red",
            },
          },
        }}
      />

      {/* Mobile Header Section */}
      {viewWidth > AppConfig.breakpoints.tablet &&
        <DanikBhaskarHeader openFooterModal={openFooterModal} setOpenFooterModal={setOpenFooterModal} setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} />
      }
      {/* Desktop Header Section */}
      {viewWidth <= AppConfig.breakpoints.tablet && (
        <DanikBhaskarHeader openFooterModal={openFooterModal} setOpenFooterModal={setOpenFooterModal} setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} />
      )}
      <div className="lg:pt-20 w-12/12 md:pt-16 sm:pt-20 ">
        <Switch>
          <div id="newsContainer"  className='flex mt-2 h-[100vh] relative flex-col main_news_container scrollbar sm:flex-row w-full' >
             
            {
              window.screen.availWidth <= 786 ?
                <div className="">  <Categories /> </div>
                :
                <div className={`overflow-y-auto ${search ? 'md:w-1/5' : ""} mx-2 sticky scrollbar`}> <CategorySection  /> </div>
            }
           <div id="parentdiv" className={`overflow-y-scroll scrollbar h-11/12 ${search ? 'md:w-3/5 w-5/5':"w-5/5"}`}> <Route exact path="/" component={Home} />
            <Route exact path={AppConfig.routes.newsDetail} component={NewsDetail} />
            <Route exact path={AppConfig.routes.Cms} component={Cmsdata} />
            <Route exact path='/:type/:slug/:id' component={NewsByCategory} />
            <Route exact path={AppConfig.routes.videonews} component={Videonews}/> 
            <Route exact path='/search_news' component={Search} />
            
             </div>
           <div className={`overflow-y-auto ${search ? 'w-1/5':""} scrollbar`}> {window.screen.availWidth <= 640 ? null
              :
              <AdvertisementSection />}
              </div>       
          </div>
        </Switch>
      </div>
      {window.location.href.includes('cart-detail') || window.location.href.includes('checkout') ?
        <FlipkartFooter />
        :
        null
      }
    </BrowserRouter>
    )
  );
}
export default Routing;
