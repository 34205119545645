import axios from "axios";
import { withRouter } from "react-router";
import { ActionTypes } from "../../Redux/Contants/ActionType";
import { showMessage } from "../../Redux/Reducers/messageSlice";
import store from "../../Redux/Store";
import AppConfig from "../../AppConfig";
import FuseUtils from "../../Utils/FuseUtils";
import http from "../../Utils/http";
import { reject } from "lodash";
/* eslint-disable camelcase */

class ApiService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  setInterseptorRequest = () => {
    axios.interceptors.request.use((req) => {
      let accessToken = this.getAccessToken();
      req.headers.authorization = "my secret token";
      req.baseURL = AppConfig.apiUrl;
      req.headers.accept = "application/json";
      req.headers["Content-Type"] = "application/json";
      req.headers.authorization = accessToken
        ? "Bearer " + accessToken
        : "Bearer ";
      req.headers.Authorization = accessToken
        ? "Bearer " + accessToken
        : "Bearer ";
      return req;
    });
  };

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            (err.response.code === 200) === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
            // JwtService.removeUserData();
          }
          throw err;
        });
      }
    );
  };

  getDialingCode = (type, page) => {
    return new Promise((resolve, reject) => {
      http.get("/user/getDialingCode").then((response) => {
        if (response.code === 200) {
          resolve(response);
        } else if (response.code === 504) {
          // store.dispatch(
          //   showMessage({ message: response.message, variant: "error" })
          // );
        } else {
          reject(response);
        }
      });
    });
  };


  readNotifications = (form) => {
    return new Promise((resolve, reject) => {
      http.get("user/customer/readNotifications", form).then((response) => {
        if (response) {
          if (response.code === 200) {
            store.dispatch({
              type: ActionTypes.ADD_USER_Notification_COUNT,
              payload: 0,
            });
            resolve(response);
          } else if (response.code === 422) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "success" })
            // );

            resolve(response);
          } else {
            // store.dispatch(
            //   showMessage({ message: response.errors, variant: "error" })
            // );
          }
        } else {
          // store.dispatch(
          //   showMessage({ message: response.errors, variant: "error" })
          // );
          reject(response);
        }
      });
    });
  };

  getCmsData = () => {
    return new Promise((resolve, reject) => {
      http.get("user/cms").then((response) => {
        if (response.code === 200) {
          resolve(response);
          store.dispatch({
            type: ActionTypes.ADD_CMS,
            payload: response.data.data.cms,
          });
          
        } 
         else {
          reject(response);
        }
      });
    });
  };

  getConfig = () => {
    return new Promise((resolve, reject) => {
      http.get("/user/config").then((response) => {
         if (response.code === 200) {
          resolve(response);
            window.sessionStorage.setItem("prefix_id", response.data.PREFIX_ID);
            resolve(response);
          
        } else {
          reject(response);
        }
      });
    });
  };

  enquiryForm = (inquiry) => {
    return new Promise((resolve, reject) => {
        http.post('user/add/enquiry', inquiry).then((response) => {
          if(response.code === 200){
            resolve(response)
          }
        })
    })
  } 

  // sendOtp = (data) => {
  //   return new Promise((resolve, reject) => {
  //     http.post("login", data).then((response) => {
  //       if (response) {
  //         resolve(response);
  //         if (response.code === 200) {
  //           store.dispatch(
  //             showMessage({
  //               message: response.data.message,
  //               variant: "success",
  //             })
  //           );
  //         } else if (response.code === 504) {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         } else {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         }
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };

  // signUP = (data) => {
  //   return new Promise((resolve, reject) => {
  //     http.post("user-signup", data).then((response) => {
  //       if (response) {
  //         resolve(response);
  //         if (response.code === 200) {
  //           store.dispatch(
  //             showMessage({
  //               message: response.data.message,
  //               variant: "success",
  //             })
  //           );
  //         } else if (response.code === 504) {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         } else {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         }
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };
  // signUpWithEmail = (data) => {
  //   return new Promise((resolve, reject) => {
  //     http.post("login-with-password", data).then((response) => {
  //       if (response) {
  //         resolve(response);
  //         if (response.code === 200) {
  //           localStorage.setItem(
  //             AppConfig.localAppKey,
  //             response.data.data.access_token
  //           );
  //           sessionStorage.setItem(
  //             AppConfig.localAppKey,
  //             response.data.data.access_token
  //           );
  //           sessionStorage.setItem(
  //             AppConfig.localIdKey,
  //             response.data.data.user.id
  //           );
  //           store.dispatch({
  //             type: ActionTypes.ADD_USER_DATA,
  //             payload: response.data.data,
  //           });
  //           store.dispatch(
  //             showMessage({
  //               message: response.data.message,
  //               variant: "success",
  //             })
  //           );
  //         } else if (response.code === 504) {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         } else {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         }
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };

  // verifyOtp = (data) => {
  //   return new Promise((resolve, reject) => {
  //     http.post("otp/verify", data).then((response) => {
  //       if (response) {
  //         if (response.code === 200) {
  //           localStorage.setItem(
  //             AppConfig.localAppKey,
  //             response.data.data.access_token
  //           );
  //           sessionStorage.setItem(
  //             AppConfig.localAppKey,
  //             response.data.data.access_token
  //           );
  //           sessionStorage.setItem(
  //             AppConfig.localIdKey,
  //             response.data.data.user.id
  //           );
  //           store.dispatch({
  //             type: ActionTypes.ADD_USER_DATA,
  //             payload: response.data.data,
  //           });

  //           setTimeout(() => {
  //             resolve(response);
  //           }, 200);
  //         } else if (response.code === 504) {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //         } else if (response.code === 422) {
  //           store.dispatch(
  //             showMessage({ message: response.message, variant: "error" })
  //           );
  //           resolve(response);
  //         }
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };



  // getFedexToken = (id) => {
  //   return new Promise((resolve, reject) => {
  //     http.post("user/fedex-tokan").then((response) => {
  //       if (response) {
  //         resolve(response);
  //         if (response.code === 200) {
  //           // store.dispatch(
  //           //   showMessage({
  //           //     message: response.data.success,
  //           //     variant: "success",
  //           //   })
  //           // );
  //         } else if (response.code === 504) {
  //           // store.dispatch(
  //           //   showMessage({ message: response.message, variant: "error" })
  //           // );
  //         } else {
  //           // store.dispatch(
  //           //   showMessage({ message: response.message, variant: "error" })
  //           // );
  //         }
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };

 

  getNotifications = (id) => {
    return new Promise((resolve, reject) => {
      http.get("user/customer/myNotifications").then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          } else {
            // store.dispatch(
            //   showMessage({ message: response.message, variant: "error" })
            // );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getFollowingDetails = (page) => {
    return new Promise((resolve, reject) => {
      http.get(`user/myFollowings`).then((response) => {
        if (response) {
          resolve(response);
          if (response.code === 200) {
            // store.dispatch(
            //   showMessage({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else if (response.code === 504) {
          } else {
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getNews = (form) => {
    return new Promise((resolve, reject) => {
      http.post("/user/newsHeadlines", form).then((response) => {
        if (response) {
          resolve(response);
        }
      });
    });
  };
  getNewsDetail = (form) => {
    return new Promise((resolve, reject) => {
      http.post("/user/getNewsDetailsById ", form).then((response) => {
        if (response) {
          resolve(response);
          // res?.data?.NewsList
          store.dispatch({
            type: ActionTypes.NEWS_DATA,
            payload : response.data.NewsList.data
          })
        }
      });
    });
  };

  getAllCities = (form) => {
    return new Promise((resolve, reject) => {
      http.get("/user/all_cities").then((response) => {
        if (response) {
          resolve(response);
        }
      });
    });
  };

  getVideoapi = () => {
    return new Promise((resolve, reject) => {
      http.get(`user/video-news`).then((response) => {
        if (response) {
          resolve(response);
        }else {
          reject(response)
        }
      });
    });
  };
  getAreaData = (id) => {
    return new Promise((resolve, reject) => {
      http.get(`/user/getAreas/${id}`).then((response) => {
        if (response) {
          resolve(response);
        }
      });
    });
  };

  getNewsCategories = () => {
    return new Promise((resolve, reject) => {
      http.post("/user/getCategories").then((response) => {
        if (response) {
          resolve(response);
          store.dispatch({
                  type: ActionTypes.ADD_CATEGORIES,
                  payload : response.data.CategoriesDetails
                })
          
        } else {
          reject(response);
        }
      });
    });
  };

  getaddbanner = () => {
    return new Promise((resolve, reject) => {
      http.get("/user/ads-banner").then((response) => {
        if (response) {
          resolve(response);
          store.dispatch({
            type: ActionTypes.ADS_BANNER,
            payload: response.data.AdsBanner,
          });
        }
      });
    });
  };

  updateProfile = (form) => {
    return new Promise((resolve, reject) => {
      http.post("user/profileUpdate", form).then((response) => {
        if (response) {
          if (response.code === 200) {
            store.dispatch(
              showMessage({
                message: response.data.message,
                variant: "success",
              })
            );
            resolve(response);
          } else if (response.code === 504) {
            store.dispatch(
              showMessage({ message: response.data.message, variant: "error" })
            );
          } else {
            store.dispatch(
              showMessage({ message: response.message, variant: "error" })
            );
          }
        } else {
          reject(response);
        }
      });
    });
  };

  getAccessToken = () => {
    return window.sessionStorage.getItem(AppConfig.localAppKey);
  };
}

// Api for news mocs website

const instance = new ApiService();

export default withRouter(instance);
