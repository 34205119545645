import React, { useState } from 'react'
import { useEffect } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useHistory, useParams } from 'react-router-dom'
import ApiService from '../../../../Services/apiService'
import fire from '../../../../../src/Assets/images/fire.png'
import { Helmet } from 'react-helmet'

export default function Categories(props) {

    const params = useParams()
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        ApiService.getNewsCategories().then((res) => {
            setCategories(res.data.CategoriesDetails)
            setLoading(false)
        })
    }, [])
    const [scrollTopHeight, setScrollTopHeight] = useState('')
    useEffect(() => {
        window.addEventListener('scroll', function () {
            setScrollTopHeight(document.documentElement.scrollTop)
        })


    }, [document.documentElement.scrollTop, scrollTopHeight])
    const history = useHistory()
    return (
        <>
            <div className={`flex pr-3 ${window.screen.availWidth <= 640 && `sticky flex-wrap  shadow-flashblack`} z-2 bg-whiteBackground  flex-wrap dark:bg-darkBackground items-center sm:flex-col   px-3 flex-row   `}>
                <div style={{ marginBottom: "64px" }} id="emptyDivAtTop" >
                </div>
                <div className={` ${window.screen.availWidth <= 640 && 'flex items-center  relative  categorySlider overflow-x-auto  w-full h-full'} md:mt-0 mt-16 `} >
                    <div
                        onClick={() => {
                            history.push(`/category/top-news/top-news`)
                        }}
                        className={` md:flex items-center sm:ml-0   md:px-8 pl-4 my-4 cursor-pointer ${params.id == "top-news" ? ' p-2 rounded-md bg-primary' : ""} `}>
                        <img className='lg:w-6 lg:h-6 mx-2 h-5 w-5 ' src={fire} />
                        <div className="mx-1 whitespace-nowrap md:block flex justify-center dark:text-fontWhite lg:text-xl text-sm  text-fontDark">Top News</div>
                    </div>
                    {categories.length > 0 && categories.map((ele, key) => (
                        <div
                            onClick={() => {
                                window.scroll(0, 0)
                                history.push(`/category/${ele.slug}/${ele.id}`)
                            }}
                            className={`flex items-center md:flex-row flex-col   sm:ml-0 px-8 md:justify-start justify-center my-4 cursor-pointer ${ele.id == params.id ? ' p-1 rounded-md bg-primary' : ""} `}>
                            <img className='lg:w-6 lg:h-6 mx-2 h-5 w-5' src={ele.ImageSrc} />
                            <h1 className="mx-1 whitespace-nowrap dark:text-fontWhite lg:text-lg text-sm  text-fontDark">{ele.name}</h1>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}
