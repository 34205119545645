import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ApiService from "../Services/apiService";
import AppConfig from "../AppConfig";
import News from "../Components/HomeSpecificComponents/NewsSection/News";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export default function Home() {
  // const user_id = window.sessionStorage.getItem("user_id");
  const [loading, setLoading] = useState(true);
  const scrollToTop = () => {
    alert('scroll')
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    // typeof addbanner !== undefined && (
    <>
      {/* {console.log(addbanner)} */}
      {!loading && (
        <Helmet>
          <title>{`${AppConfig.siteName}- Title`} </title>
          <meta
            name="description"
            content="All Data"
            // data-react-helmet="true"
          />
          <meta property="og:site_name" content={`${AppConfig.siteName}`} />
          <meta property="og:title" content={`title-${AppConfig.siteName}`} />
          <meta property="og:type" content="Website"></meta>
          <meta property="og:url" content={`${AppConfig.siteName} ---`} />
          <meta property="og:image" content="Imagesrc" />
        </Helmet>
      )}
      <div
        id="newsContainer"
        className="flex mt-2  relative flex-col sm:flex-row h-auto w-12/12 "
      >
        <News />
      
      </div>
     
    </>
    // )
  );
}
